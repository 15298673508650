import React, { useState, useEffect, createRef, useContext } from "react"
import { CursorContext } from "../../../context/cursorContext"
import "./peopleCard.scss"

const PeopleCard = ({ element }) => {
  const [active, toggle] = useState(false)
  const [background, setBackground] = useState("var(--lightgrey)")
  const { cursorConfig, setCursor } = useContext(CursorContext)

  useEffect(() => {
    const bg =
      element.background.presets !== "custom"
        ? element.background.presets
        : element.background.custom
    setBackground(bg)
  })

  const textDiv = createRef()
  const [arrow, setArrow] = useState(false)

  useEffect(() => {
    if (textDiv)
      setArrow(
        textDiv.current.scrollHeight > textDiv.current.offsetHeight &&
          textDiv.current.scrollTop + textDiv.current.offsetHeight <
            textDiv.current.getElementsByTagName("span")[0].scrollHeight
      )

  }, [textDiv])

  const compareOnScroll = () => {
    if (textDiv) setArrow(
      textDiv.current.scrollHeight > textDiv.current.offsetHeight &&
        textDiv.current.scrollTop + textDiv.current.offsetHeight <
          textDiv.current.getElementsByTagName("span")[0].scrollHeight
    )
  }

  if (element.personImage) {
    return (
      <div className={active ? "people-card active" : "people-card"}>
        <div className={active ? "inner active" : "inner"}>
          <div className="front">
            {/* {element.personImage.localFile && <GatsbyImage
              image={element.personImage.localFile.childImageSharp.gatsbyImageData}
            />} */}
            <img
              src={element.personImage.sourceUrl}
              srcSet={element.personImage.srcSet}
            />
          </div>
          <div className="back" style={{ background: background }}>
            <div
              className="left"
              dangerouslySetInnerHTML={{ __html: element.textLeft }}
            />
            <div
              ref={textDiv}
              onScroll={compareOnScroll}
              className="right"
              dangerouslySetInnerHTML={{ __html: element.textRight }}
            />
            {arrow && (
              <div
                className="arrow-down"
                onMouseEnter={() =>
                  setCursor({
                    ...cursorConfig,
                    rotation: -30,
                    scale: "1",
                    color: "var(--black)",
                    shape: "default",
                  })
                }
                onMouseLeave={() =>
                  setCursor({
                    ...cursorConfig,
                    rotation: -30,
                    scale: "1",
                    color: "var(--darkgrey)",
                    shape: "default",
                  })
                }
                onClick={() => {
                  textDiv.current.scrollBy({
                    top: textDiv.current.clientHeight * 0.25,
                    behavior: "smooth",
                  })
                }}
              ></div>
            )}
          </div>
        </div>
      </div>
    )
  } else return <div />
}

export default PeopleCard
