import { graphql } from "gatsby"
import React, { useContext } from "react"
import Background from "../components/atoms/background"
import PeopleCard from "../components/molecules/peopleCard/peopleCard"
import SEO from "../components/seo"
import { CursorContext } from '../context/cursorContext'

export const query = graphql`
  query PeoplePage {
    page: wpPage(slug: { eq: "people" }) {
      id
      title
      slug
      background {
        color: presets
        custom
      }
      peopleFields {
        sideText
        people {
          textLeft
          textRight
          background {
            presets
            custom
          }
          personImage {
            sourceUrl
            srcSet
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

const PeoplePage = ({ data, location }) => {
  const { page } = data
  const { cursorConfig, setCursor } = useContext(CursorContext);

  if (page !== null) {
    return (
      <div 
        onMouseEnter={() => setCursor({
            ...cursorConfig,
            rotation: -30,
            scale: '1',
            color: 'var(--darkgrey)',
            shape: "default"
        })}
      >
        <SEO title="people" />
        <Background preset={page.background.color} custom={page.background.custom} />
        <div
          className={true ? "side-description active" : "side-description"}
          dangerouslySetInnerHTML={{ __html: page.peopleFields.sideText }}
        />
        <div className="container people-grid">
          {page.peopleFields.people.map((element, index) => {
            return <PeopleCard element={element} key={index} />
          })}
        </div>
      </div>
    )
  } else {
    return <></>
  }
}

export default PeoplePage
